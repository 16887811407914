<template>
  <v-dialog persistent v-model='dialog' width='720px'>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text @click="openDialog()">
        <v-icon left small color="neuter">mdi-file-document-multiple-outline</v-icon>
        {{ $t('reports.titles.generateReports') }}
      </v-btn>
    </template>
    <v-card elevation='0'>
      <v-card-title class='secondary white--text py-4'>
        {{ $t('reports.titles.generateReports') }}
      </v-card-title>
      <v-card-text class='pa-6' v-if="dialog">
        <v-row justify="start">
          <v-col cols="5" class="mr-0 pr-0">
            <LotFilterComponent
              class="mr-1"
              ref="lots"
              :multiple="false"
              :show-selected="false"
              :required="true"
              :select-props="{
                outlined: true,
                dense: true,
                solo: false,
                backgroundColor: 'white',
                flat: false,
                clearable: true,
                hideDetails: false
              }"
              @data:change="getProducts($event)" item-text="name" item-value="name"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small>
                  <span>{{ item.name | truncate(10) }}</span>
                </v-chip>
              </template>
            </LotFilterComponent>
          </v-col>
          <v-col cols="5" class="mx-0 px-0">
            <v-autocomplete
              :no-data-text="$t('general.fields.noDataAvailable')"
              persistent-hint
              small
              outlined
              dense
              ref="productFilters"
              clearable
              item-color="primary"
              :items="products"
              v-model="product"
              small-chips
              hide-details
              multiple
              color="primary"
              :label="$t('records.label.titles.product')"
              :loading="loadingProducts"
              :disabled="!(products && products.length > 0) || !lot"
              @click:clear="clearProductData"
            >
              <template v-slot:label>
                {{$t('records.label.titles.product')}} <span class="red--text"><strong>*</strong></span>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item | truncate(15) }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey&#45;&#45;text caption"
                >(+{{ product.length - 1 }}
                  {{ $t('general.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 360px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols='2'>
            <v-btn
              :disabled="!lot || !(product && product.length > 0)"
              class="ml-1 t-bw-primary--text"
              elevation="0"
              color="primary"
              @click="add()"
            >
              <v-icon color="t-bw-primary">
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols='12' class="mx-0 px-0">
            <v-data-table :items='toSave'
                          :headers='headers'
                          :footer-props="{
                            ...rowsPerPageItemsOptions,
                            itemsPerPageText: $t('general.table.itemsPerPageText'),
                            pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
                          }"
                          :no-data-text="$t('general.ui.not_data_found')"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer />
        <v-btn
          color="neuter"
          text
          small
          @click="closeDialog()"
        >
          {{ $t("general.buttons.close") }}
        </v-btn>
        <v-btn
          small elevation="0" color="primary" class="t-bw-primary--text"
          @click="generateReport()"
          :disabled='!(toSave.length > 0)'
        >
          {{ $t("general.buttons.generate") }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import sortList from '@/services/OrderListService'
  import i18n from '@/plugins/i18n'
  import ReadMore from '@/components/ReadMoreComponent'
  import _ from 'lodash'
  import ASelectLots from "@/components/common/AAutocomplete/ASelectLots.vue";
  import LotFilterComponent from "@/components/common/nomenclators/LotFilterComponent.vue"

  export default {
    name: 'GenerateNewTraceabilityReportTable',
    components: { LotFilterComponent, ReadMore, ASelectLots },

    props: {
      reportType: {
        type: String,
        required: true
      }
    },

    data:() => ({
      profile: null,
      dialog: false,
      product: [],
      products: [],
      lot: null,
      lotsIds: [],
      toSave: [],
      loadingProducts: false,
      checkboxState: {
        products: false,
        lots: false,
      },
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [5, 10],
      },
      selectAllCheck: false
    }),

    computed: {
      ...mapGetters({
        lots: 'details/lots',
        selectRule: 'general/selectRule',
      }),
      headers: () => [
        { text: i18n.t('reports.fields.lot'), value: "lot" },
        { text: i18n.t('records.label.titles.product'), value: "product" },
      ]
    },

    mounted() {
      this.profile = JSON.parse(localStorage.getItem('profile'))
    },

    methods: {
      ...mapActions({
        fetchListStructureProducts: 'details/fetchListStructureProducts',
        generateReportsByLotAndProduct: 'reports/generateReportsByLotAndProduct',
      }),

      /**
       * Adds new lot-product combination to the generation list
       * Ensures no duplicates are added
       */
      add() {
        _.forEach(this.product, (p) => {
          const data = _.filter(this.toSave, { lot: this.lot, product: p })
          if (!(data.length > 0)) {
            this.toSave.push({
              product: p,
              lot: this.lot
            })
          }
        })
        this.clearSelections();
      },

      openDialog() {
        this.$emit('closeMenu', false)
        this.dialog = true
      },
      closeDialog() {
        this.$emit('closeMenu', false)
        this.dialog = false
        this.clearLotData()
      },

      /**
       * Triggers report generation for multiple lot-product combinations
       * @see generateReportsByLotAndProduct
       */
      generateReport() {
        let reportType = this.normalizeReportType();
        
        this.generateReportsByLotAndProduct({
          lotAndProductList: this.toSave,
          reportType: reportType
        });
        this.closeAndClean();
      },

      clearProductData() {
        this.checkboxState.products = false
        this.product = []
      },
      async getProducts(lot) {
        if (lot === null) {
          this.checkboxState.products = false
          this.product = []
          this.products = [];

          return;
        }

        this.loadingProducts = true;
        this.product = null;
        this.lot = lot;
        await this.fetchListStructureProducts([this.profile, lot, this.$toast, false])
          .then((data) => {
            this.products = data;
          })
          . finally(() => {
            this.loadingProducts = false
          })
      },
      clearLotData() {
        this.checkboxState.lots = false
        this.checkboxState.products = false
        this.lot = null
        this.$root.$emit("clear:selected:lots")
        this.product = []
        this.toSave = []
      },
      orderData(list, order) {
        return sortList.orderListByUppercase(list, order)
      },

      /**
       * Normalizes report type string based on current selection
       * @returns {string} Normalized report type
       * @private
       */
      normalizeReportType() {
        switch (this.reportType) {
          case "rastreability":
            return "TRACEABILITY";
          case "recall":
            return "RECALL";
          default:
            return "TRACEABILITY";
        }
      },

      /**
       * Closes dialog and cleans up state
       * @private
       */
      closeAndClean() {
        this.dialog = false;
        this.clearLotData();
      }
    }
  }
</script>

<style scoped>
tbody {
  height: 200px !important;
}
</style>