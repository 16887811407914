import { default as http } from './HttpService'

class DocumentsService {
  constructor() {}

  documents() {
    return http.client.post(`v1/company/filter/documents`, {})
  }

  documentsFiltered(filters) {
    return http.client.post('v1/document/filter/documents_filters', filters)
  }

  exportCSV(filters) {
    return http.client.post('v1/document/export/documents_filters', filters, {
      responseType: 'blob',
    })
  }

  documentById(id) {
    return http.client.get(`/v1/document/${id}`)
  }

  versionsByDocumentId(id) {
    return http.client.post(`/v1/document/with_versions`, {
        documentId: id
    })
  }

  filterSearch(data) {
    return http.client.post(`/v1/document/string_filter`, data)
  }

  save(
    document_json,
    document_file,
    storage_devices_json,
    editOrSave,
    fileEditChange,
    renew
  ) {
    let fd = new FormData()
    fd.append('document_json', JSON.stringify(document_json))
    fd.append('document_file', document_file)

    fd.append('locations', JSON.stringify({
      storageDeviceIds: storage_devices_json,
      primaryId: document_json.primaryLocation.id,
      renew: !!renew,
      fileEditChange: fileEditChange
    }))

    if (editOrSave > -1) {
      return http.client.put(`v1/document`, fd, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    } else {
      return http.client.post(`v1/document`, fd, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    }
  }

  getEvidencas(record) {
    return http.client.get(`v1/record/${record}/zip`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/zip',
      },
    })
  }

  getFileExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined
  }

  formatterSizeUnit(size) {
    if (size) {
      let result = parseInt(size)
      if (result < 1024) {
        return result + ' B'
      } else if (result < 1024 * 1024) {
        return parseInt(result / 1024) + ' KB'
      } else if (result < 1024 * 1024 * 1024) {
        return parseInt(result / (1024 * 1024)) + ' MB'
      } else {
        return parseInt(result / (1024 * 1024 * 1024)) + ' GB'
      }
    }
  }

  selectIcon(extension) {
    switch (extension) {
      case 'pdf':
      case 'application/pdf':
        return 'mdi-file-pdf-box';
      case 'xls':
      case 'xlsx':
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'mdi-file-excel';
      case 'cvs':
        return 'mdi-file-table';
      case 'png':
      case 'image/png':
        return 'mdi-file-image';
      case 'jpg':
      case 'image/jpg':
        return 'mdi-file-image';
      case 'jpeg':
      case 'image/jpeg':
        return 'mdi-file-image';
      case 'bmp':
      case 'image/bmp':
        return 'mdi-file-image';
      case 'msword':
        return 'mdi-file-image';
      case 'application/zip':
      case 'zip':
      case 'rar':
        return 'mdi-folder-zip';
    }
  }

  downloadDocument(id) {
    return http.client.get(`v1/document/${id}/zip`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/zip',
      },
    })
  }

  downloadDocumentFormVersion(id, version) {
    return http.client.get(`v1/document/${id}/${version}/zip`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/zip',
      },
    })
  }

  delete(id) {
    return http.client.delete('/v1/document/' + id)
  }

  validateImport(module, file) {
    const formData = new FormData()
    formData.append('module', module)
    formData.append('file', file)

    return http.client.post('/v1/import/validate', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }
}

export default new DocumentsService()
