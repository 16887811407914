<template>
  <v-dialog persistent v-model="isOpen" max-width="800px" scrollable>
    <v-card light color="white">
      <v-card-title class="d-flex justify-space-between px-6"
                    :class="[
                        { 'border-errored__show' : recordSelected.status === 'ERRORED' },
                        { 'border-validating__show' : recordSelected.status === 'VALIDATING' },
                        { 'border-pending__show' : recordSelected.status === 'PENDING' }
                      ]"
      >
        <v-toolbar fixed flat>
            <span class="headline mr-4" style="font-weight: bold;">
              {{ $t('general.titles.details') }}
            </span>
            <v-chip small text-color="white" color="validating" v-if="recordSelected.status === 'VALIDATING'" class="chip-validating">
              {{ $t('records.filters.status.validating') }}
            </v-chip>
            <v-chip small text-color="white" color="pending" v-if="recordSelected.status === 'PENDING'" class="chip-pending">
              {{ $t('records.filters.status.pending') }}
            </v-chip>
            <v-chip small text-color="white" color="error" v-if="recordSelected.status === 'ERRORED'" class="chip-errored">
              {{ $t('records.filters.status.errored') }}
            </v-chip>
        </v-toolbar>

        <span class='pr-6' v-if='logRecordSelected.blockchainSave && logRecordSelected.blockchainHash'>
          <v-card-subtitle>
            <a class='lot__chip my-1 py-1 d-flex justify-start' target='_blank'
               style="font-size: 13px;"
               :href="logRecordSelected.url">
              <strong>Blockchain Hash:&nbsp </strong>{{ logRecordSelected.blockchainHash | truncate(10) }}
            </a>
          </v-card-subtitle>
        </span>
      </v-card-title>

      <v-divider />

      <v-card-text class="pa-6">
        <v-container>
          <v-row v-if="isOpen">
            <div class="d-flex flex-wrap">
              <v-card elevation="0" width="100%" class="my-2">
                <v-card-title class="pb-1 pt-1 primary darken-5 t-bw-primary--text font-weight-medium">
                  <span style="font-size: 16px">{{ $t('records.label.titles.registryData') }}</span>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text
                  style="
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        padding-top: 0 !important;
                      "
                >
                  <v-card elevation="0" width="100%" class="mb-3">
                    <v-card-text
                      style="
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            padding-top: 0 !important;
                          "
                    >
                      <div class="d-flex flex-wrap mb-2 pa-3 background__body">
                        <div style="width: 200px; padding: 5px" v-for="(values, i) in headers" :key="i" v-if="!['action', 'status', 'statusLine'].includes(values.value) && i < 8">
                          <p class="black--text mb-0 font-weight-bold">{{ values ? $t(values.text) : '' }}:</p>
                          <p class="black--text mb-2" v-if="values.value !== 'uniqueIdRepresentation'">{{ getDetailsMainData(values.value) || '-' }}</p>
                          <UniqueIdRepresentationComponent v-else :unique-id="recordSelected.uniqueIdRepresentation" format-style="raw" styles="font-size: 16px; color: black" :formated="true" />
                        </div>

                        <div style="width: 200px; padding: 5px">
                          <p class="black--text mb-0 font-weight-bold">{{ $t('records.fields.registerDate') }}:</p>
                          <p class="black--text mb-2">{{ getDetailsMainData('sent_date_time') || '-' }}</p>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>

                <v-divider />
              </v-card>

              <div style="width: 200px" v-if="existStructures" v-for="item in getStructures" :key="item.id">
                <div  class="ma-2 pa-2">
                  <p class="black--text mb-0 font-weight-bold">{{ translate(item.structure.language_key, true) }}:</p>
                  <p class="black--text mb-0" v-if="item.recordStructureValue.record_value.length > 0">
                    <span v-for="structureName in item.recordStructureValue.record_value">
                      {{ getValue(item.structure, structureName) }}
                    </span>
                  </p>
                  <p v-else class="black--text mb-0">-</p>
                </div>
              </div>

              <v-card elevation="0" width="100%" class="my-2" v-for="(group, index) in getGroups" :key="index" v-if="existGroups">
                <v-card-title class="pb-1 pt-1 primary darken-5 t-bw-primary--text font-weight-medium">
                  <span style="font-size: 16px">{{ translate(group.languageKey, true).toUpperCase() }}</span>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text
                  style="
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        padding-top: 0 !important;
                      "
                >
                  <v-card elevation="0" width="100%" class="mb-3" v-for="row in group.rows" :key="row.groupRow">
                    <v-card-text
                      style="
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        padding-top: 0 !important;
                      "
                    >
                      <div class="d-flex flex-wrap mb-2 pa-3 background__body">
                        <div style="width: 200px" v-for="values in row.values">
                          <p class="black--text mb-0 font-weight-bold">{{ translate(values.structure.language_key, true) }}:</p>
                          <p v-if="values.recordStructureValue.record_value.length > 0">
                            <span class="black--text mb-2" v-for="(item, index) in values.recordStructureValue.record_value" :key="index">{{ getValue(values.structure, item) || '-' }}</span>
                          </p>
                          <p class="black--text mb-2" v-else>-</p>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </div>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions class="pa-6">
        <v-btn v-if="recordSelected.evidences && recordSelected.evidences.length > 0" text icon :loading="loadingEvidences" :disabled="loadingEvidences" class="ma-2 white--text" @click="getEvidences">
          <v-icon color="primary"> mdi-download-multiple </v-icon>
        </v-btn>
        <DownloadErrorsComponent v-if="recordSelected.status === 'ERRORED'" :item="recordSelected" :is-icon="true" icon-color="error" />
        <v-spacer />
        <v-btn class="mx-4 t-bw-primary--text" small elevation="0" color="primary" @click="closeDialog">
          {{ $t('general.buttons.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from "moment";
  import LanguageService from "@/services/LanguajeService";
  import UniqueIdRepresentationComponent from "@/module/records/components/UniqueIdRepresentationComponent.vue";
  import DownloadErrorsComponent from "@/module/records/components/DownloadErrorsComponent.vue";
  import DocumentsService from "@/services/DocumentsService"
  import i18n from "@/plugins/i18n"

  export default {
    components: {
      UniqueIdRepresentationComponent,
      DownloadErrorsComponent
    },
    props: {
      value: {
        type: Boolean,
        default: false
      },
      recordSelected: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        loadingEvidences: false
      };
    },
    computed: {
      isOpen: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      },
      logRecordSelected() {
        return this.$store.getters['records/recordLog'];
      },
      headers() {
        return this.$store.getters['records/headers'];
      },
      existStructures() {
        return (
          this.recordSelected.recordStructureValuesOrganized.structures &&
          this.recordSelected.recordStructureValuesOrganized.structures.length > 0
        );
      },
      existGroups() {
        return (
          this.recordSelected.recordStructureValuesOrganized.groups &&
          this.recordSelected.recordStructureValuesOrganized.groups.length > 0
        );
      },
      getStructures() {
        return this.recordSelected.recordStructureValuesOrganized.structures || [];
      },
      getGroups() {
        return this.recordSelected.recordStructureValuesOrganized.groups || [];
      }
    },
    methods: {
      closeDialog() {
        this.isOpen = false;
        this.$store.commit('records/RESTORE_RECORD');
        this.$store.commit('records/RESTORE_RECORD_LOG');
      },
      getDetailsMainData(type) {
        console.log('type:', type);
        console.log('value:', this.recordSelected[type]);
        
        switch (type) {
          case 'record_date_time':
          case 'sent_date_time':
            return this.getDateFormat(this.recordSelected[type]);
          case 'sdi_origin_name':
          case 'sdi_origin':
            return typeof this.recordSelected.sdi_origin === 'object' 
              ? this.recordSelected.sdi_origin.name 
              : this.recordSelected.sdi_origin || '-';
          case 'sdi_destination_name':
          case 'sdi_destination':
            return typeof this.recordSelected.sdi_destination === 'object'
              ? this.recordSelected.sdi_destination.name
              : this.recordSelected.sdi_destination || '-';
          case 'process_name':
            return this.recordSelected.process?.language_key?.[0]?.label || '-';
          case 'person_in_charge_name':
            return this.recordSelected.person_in_charge?.name || '-';
          case 'uniqueIdRepresentation':
            return this.recordSelected.uniqueIdRepresentation || '-';
          default:
            return this.recordSelected[type] || '-';
        }
      },
      getEvidences() {
        this.loadingEvidences = true
        try {
          DocumentsService.getEvidencas(this.recordSelected.id).then(
            response => {
              const FileSaver = require('file-saver')
              const blob = new Blob([response.data], {
                type: 'application/zip',
              })
              FileSaver.saveAs(blob, 'evidences.zip')
            }
          )
        } catch (error) {
          this.$toast.warning(i18n.t('records.notification.evidencesCero'), {
            queueable: true,
            icon: "mdi-alert",
          })
        }
        this.loadingEvidences = false
      },
      getDateFormat(dateString) {
        if (dateString) return moment(new Date(dateString)).format(this.$store.getters['ui/GET_DATE_FILTERS'].dateFormat);
        return null;
      },
      translate(key) {
        return LanguageService.getKey3(key);
      },
      getValue(structure, value) {
        if (Array.isArray(value) && value.length === 0) return '-';

        if (value) {
          let dateFormatted = (LanguageService.getKey3(value) + ".000Z").slice(0, 19);

          switch (structure.structure_type) {
            case 'DATA':
              return moment(dateFormatted).format("DD / MM / YYYY");
            case 'TIME':
              return moment(dateFormatted).format("HH:mm");
            case 'DATATIME':
              return moment(dateFormatted).format("DD / MM / YYYY HH:mm");
            default:
              return LanguageService.getKey3(value);
          }
        } else {
          return '-';
        }
      }
    }
  };
</script>

<style scoped lang="scss">
  .background__body {
    background-color: color-mix(in srgb, var(--v-primary-base) 8%, transparent);
  }

  .v-dialog {
    max-width: 800px;
  }

  .v-card-title {
    display: flex;
    justify-content: space-between;
  }

  .chip-validating {
    background-color: var(--v-validating-base);
    color: #fff;
  }

  .chip-pending {
    background-color: var(--v-pending-base);
    color: #fff;
  }

  .chip-errored {
    background-color: var(--v-error-base);
    color: #fff;
  }

  .pa-4 {
    padding: 16px !important;
  }

  .v-card-actions {
    display: flex;
    justify-content: space-between;
  }
</style>
