<template>
  <div>
    <v-dialog v-model='dialog' persistent width='600px'>
      <template v-slot:activator='{ on, attrs }'>
        <v-btn text @click='openDialog()'>
          <v-icon color='neuter' left small>mdi-upload-multiple</v-icon>
          CSV
        </v-btn>
      </template>
      <v-card>
        <v-card-title class='headline secondary t-bw-secondary--text'>
          {{ $t('reports.titles.importConfigCSV') }}
          <v-spacer />
        </v-card-title>
        <v-card-text class="pa-6">
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols='12'>
                <v-autocomplete v-model='process' :disabled='loading' :items='allProcess'
                  :label="$t('records.fields.process')" :loading='loading'
                  :no-data-text="$t('general.fields.noDataAvailable')" class='d-flex align-baseline' clearable
                  item-text='proccess_name' item-value='proccess_name' return-object small-chips outlined dense
                  :rules="required" @change='clearErrors()'>
                  <template v-slot:item='{ item }'>
                    <template>
                      <v-list-item-content v-text='translate(item.language_key, true)' />
                    </template>
                  </template>
                  <template v-slot:selection='{ item, index }'>
                    <v-chip small>
                      <span>{{ translate(item.language_key, true) }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols='12'>
                <v-file-input v-model='file' :label="$t('general.buttons.upload_doc')"
                  :rules='requireRulesSizeAndType(file).concat(required)' append-icon='mdi-file-excel' prepend-icon=''
                  outlined dense @change='clearErrors()'>
                </v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn :disabled='loading' color='neuter' small text @click='dialogImportCSVClose' v-if='errors.length > 0'>
            {{ $t('general.buttons.close') }}
          </v-btn>
          <span v-if='!(errors.length > 0)'>
            <v-btn :disabled='loading' color='neuter' class="mr-1" small text @click='dialogImportCSVClose'>
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn :disabled='loading || !valid' :loading='loading' elevation="0" color='primary'
              class="t-bw-primary--text" small @click='importRecords()'>
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <file-import-confirmation-dialog :show-confirm-dialog="showConfirmDialog" @confirm="proceedWithImport"
      @cancel="cancelImport" />
  </div>
</template>

<script>
import XLSX from 'xlsx';
import { mapActions, mapGetters } from 'vuex';
import LanguageService from '@/services/LanguajeService';
import DocumentsService from '@/services/DocumentsService';
import FileImportConfirmationDialog from '@/components/common/FileImportConfirmationDialog.vue';

export default {
  name: 'ImportCSVComponent',

  components: {
    FileImportConfirmationDialog
  },

  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    process: null,
    language: LanguageService.getLenguajeName(),
    file: null,
    errors: [],
    isAsync: false,
    showConfirmDialog: false
  }),

  computed: {
    ...mapGetters({
      requireRulesSizeAndType: 'general/requireRulesSizeAndType',
      required: 'general/requireRules',
    }),
    config: {
      get() {
        return this.$store.getters['general/config']
      },
      set(val) {
        this.$store.commit('general/SET_CONFIG', val)
      },
    },
    allProcess: {
      get() {
        return this.$store.getters['proccess/proccess']
      },
      set(val) {
        this.$store.commit('proccess/SET_PROCCESS_LIST', val)
      },
    },
  },

  methods: {
    ...mapActions({
      importRecordsAction: 'records/importRecords',
    }),
    openDialog() {
      this.$emit('closeMenu', false)
      this.dialog = true
    },
    async importRecords() {
      if (!this.file) return

      try {
        const { data } = await DocumentsService.validateImport(
          'RECORDS',
          this.file
        )

        if (data.alreadyImported) {
          this.showConfirmDialog = true
          return
        }

        await this.proceedWithImport()
      } catch (error) {
        console.error(error)
        this.loading = false
        this.$toast.error(this.$t('general.notification.importError'))
      }
    },
    async proceedWithImport() {
      try {
        this.showConfirmDialog = false;
        this.loading = true
        
        await this.readFile(this.file)
        await this.importRecordsAction([{
          process: this.process.id,
          language: LanguageService.getLenguajeName(),
          file: this.file,
          isAsync: this.isAsync
        }, this.$toast])
      } finally {
        this.loading = false
        this.dialogImportCSVClose()
      }
    },
    cancelImport() {
      this.loading = false
      this.file = null
      this.showConfirmDialog = false
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const fileType = file.name.split('.').pop();

        reader.onload = (e) => {
          const data = e.target.result;

          if (['csv', 'xls', 'xlsx'].includes(fileType)) {
            const workbook = XLSX.read(data, { type: fileType === 'csv' ? 'string' : 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            let rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            rows = rows.filter(row => row.length > 0 && row[0] !== null && row[0] !== '' && row[0] !== undefined);

            const lastRowIndex = rows.length - 1;
            const lastRow = rows[lastRowIndex];

            const firstColumnValue = lastRow ? lastRow[0] : null;

            if (firstColumnValue !== null && !isNaN(firstColumnValue)) {
              console.log(`Última fila, primera columna: ${firstColumnValue}`);
              this.processFileData(firstColumnValue);
              resolve();
            } else {
              reject(new Error('No se pudo obtener un valor válido en la última fila.'));
            }
          } else {
            reject(new Error('Unsupported file format'));
          }
        };

        reader.onerror = (error) => reject(error);

        if (fileType === 'csv') {
          reader.readAsText(file);
        } else {
          reader.readAsBinaryString(file);
        }
      });
    },
    processFileData(lastRowValue) {
      const limit = this.config.company.import.recordImportLimit;
      this.isAsync = lastRowValue > limit;
    },
    dialogImportCSVClose() {
      this.dialog = false
      this.process = null
      this.language = LanguageService.getLenguajeName()
      this.file = null
      this.loading = false
      this.errors = []
      this.isAsync = false
      this.$refs.form.reset()
    },
    translate(key, uppercase) {
      return LanguageService.getKey3(key)
    },
    clearErrors() {
      this.count = {
        success: null,
        errors: null,
        total: null
      }
      this.errors = [];
    }
  },
}
</script>

<style scoped></style>